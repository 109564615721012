import React from 'react';
import style from './index.module.css';
import Layout from '../components/common/Layout.jsx';
import LandingBanner from '../components/landing/LandingBanner.jsx';
import LandingNavgrid from '../components/landing/LandingNavgrid.jsx';
import LandingApp from '../components/landing/LandingApp.jsx';
import { Helmet } from 'react-helmet';
import vidThumb1 from '../../src/images/landing/vidThumb1.png';
import vidThumb2 from '../../src/images/landing/vidThumb2.png';
import vidThumbPlayIcon from '../../src/images/landing/vidThumbPlayIcon.svg';

import Player from './Player.jsx';

class LandingPage extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            videoPlaying: false,
            videoPlay: null,
        };

        this.openPlayer    = this.openPlayer.bind(this);
        this.closePlayer   = this.closePlayer.bind(this);
    }

    openPlayer(videoIndex){
        console.log('player opened: ', videoIndex);
        this.setState({ videoPlaying: true, videoPlay: videoIndex });
    }

    closePlayer(){
        console.log('close player');
        this.setState({ videoPlaying: false, videoPlay: null });
    }

    render(){
        return (
            <>
                { this.state.videoPlaying && <Player closePlayer={this.closePlayer} videoIndex={this.state.videoPlay}/> }
                <Layout>
                    <Helmet>
                        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8"/>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                        <meta name="description" content="Read now for heart information in dogs."/>
                        <meta name="robots" content="index, follow"/>
                        <meta property="og:title" content="Healthy Hearts for Dogs - Boehringer Ingelheim"/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:description" content="Read now for heart information in dogs."/>
                        <meta property="og:locale" content="en_AU"/>

                        <title>Healthy Hearts for Dogs - Boehringer Ingelheim 2019</title>
                        <meta name="apple-mobile-web-app-title" content="Healthy Hearts for Dogs - Boehringer Ingelheim"/>
                        <meta property="og:url" content="https://healthyheartsfordogs.com.au"/>
                        <link rel="canonical" href="https://healthyheartsfordogs.com.au"/>
                    </Helmet>
                    <LandingBanner/>
                    <LandingNavgrid/>
                    <LandingApp/>
                    <div className={[style.VideoContainer1, style.VideoContainer].join(' ')}>
                        <div className={style.TextSection}>
                            <p className={style.VideoContainerTitle}>Let's have a heart2heart about canine heart disease</p>
                            <p className={style.TextSectionDescription}>Did you know the most common heart disease in dogs is Mitral Valve Disease?</p>
                            <p className={style.VideoContainerCTA}>Watch the video to learn more</p>
                        </div>
                        <div className={style.VideoSection}>
                            <div className={style.VideoThumbnailImage} onClick={()=>this.openPlayer(0)}>
                                <img src={vidThumb1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={[style.VideoContainer2, style.VideoContainer].join(' ')}>
                        <div className={style.TextSection}>
                            <p className={style.VideoContainerTitle}>Diagnosis of canine heart disease</p>
                            <p className={style.TextSectionDescription}>Veterinary Cardiologist, Dr Brad Gavaghan talks about treating 'Mischa' and the advantage of early diagnosis for canine heart disease.</p>
                            <p className={style.VideoContainerCTA}>Watch the video to learn more</p>
                        </div>
                        <div className={style.VideoSection}>
                            <div className={style.VideoThumbnailImage} onClick={()=>this.openPlayer(1)}>
                                <img src={vidThumb2} alt=""/>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default LandingPage;
