import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './LandingNavgrid.module.css';

class LandingNavgridImage extends React.Component {
    render(){
        return (
            <Img
                fluid={this.props.landingNavgridImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                grid1: file(relativePath: { eq: "landing/grid1.png" }) {
                    childImageSharp {
                        fluid(maxWidth:595){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                grid2: file(relativePath: { eq: "landing/grid2.png" }) {
                    childImageSharp {
                        fluid(maxWidth:595){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                grid3: file(relativePath: { eq: "landing/grid3.png" }) {
                    childImageSharp {
                        fluid(maxWidth:595){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                grid4: file(relativePath: { eq: "landing/grid4.png" }) {
                    childImageSharp {
                        fluid(maxWidth:595){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            // let isIE = /*@cc_on!@*/false || !!document.documentMode;
            let isIE = false;

            if( isIE ){
                return (
                    <div className={style.LandingNavgridShell}>
                        <div className={style.LandingNavgridContentIE}>
                            <table cellPadding='0' cellSpacing='0' border='0' role='presentation' className={style.LandingTableIE}>
                                <tbody className="LandingTbodyIE">
                                    <tr className="LandingTrIE">
                                        <td className={style.LandingTdIE}>
                                            <div className={style.NavgridElement}>
                                                <Link to="/healthy-hearts" className={style.NavgridElementLink}>
                                                    <p className={style.NavgridText}>Healthy hearts</p>
                                                    <LandingNavgridImage landingNavgridImage={data.grid1} className={style.NavgridImage} />
                                                </Link>
                                            </div>
                                        </td>
                                        <td className={style.LandingTdIE}>
                                            <div className={style.NavgridElement}>
                                                <Link to="/heart-disease" className={style.NavgridElementLink}>
                                                    <p className={style.NavgridText}>Heart disease</p>
                                                    <LandingNavgridImage landingNavgridImage={data.grid2} className={style.NavgridImage} />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className="LandingTbodyIE">
                                    <tr className="LandingTrIE">
                                        <td className={style.LandingTdIE}>
                                            <div className={style.NavgridElement}>
                                                <Link to="/early-diagnosis" className={style.NavgridElementLink}>
                                                    <p className={style.NavgridText}>Benefits of early diagnosis</p>
                                                    <LandingNavgridImage landingNavgridImage={data.grid3} className={style.NavgridImage} />
                                                </Link>
                                            </div>
                                        </td>
                                        <td className={style.LandingTdIE}>
                                            <div className={style.NavgridElement}>
                                                <Link to="/care-and-treatment" className={style.NavgridElementLink}>
                                                    <p className={style.NavgridText}>Care and treatment</p>
                                                    <LandingNavgridImage landingNavgridImage={data.grid4} className={style.NavgridImage} />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            } else {
                return (
                <div className={style.LandingNavgridShell}>
                    <div className={style.LandingNavgridContent}>
                        <div className={style.NavgridElement}>
                            <Link to="/healthy-hearts" className={{...style.NavgridElementLink, ...style.NavgridElement1}}>
                                <p className={style.NavgridText}>Healthy hearts</p>
                                <LandingNavgridImage landingNavgridImage={data.grid1} className={style.NavgridImage}/>
                            </Link>
                        </div>
                        <div className={style.NavgridElement}>
                            <Link to="/heart-disease" className={{ ...style.NavgridElementLink, ...style.NavgridElement2}}>
                                <p className={style.NavgridText}>Heart disease</p>
                                <LandingNavgridImage landingNavgridImage={data.grid2}  className={style.NavgridImage}/>
                            </Link>
                        </div>
                        <div className={style.NavgridElement}>
                            <Link to="/early-diagnosis" className={{...style.NavgridElementLink, ...style.NavgridElement3}}>
                                <p className={style.NavgridText}>Benefits of early diagnosis</p>
                                <LandingNavgridImage landingNavgridImage={data.grid3}  className={style.NavgridImage}/>
                            </Link>
                        </div>
                        <div className={style.NavgridElement}>
                            <Link to="/care-and-treatment" className={{...style.NavgridElementLink, ...style.NavgridElement4}}>
                                <p className={style.NavgridText}>Care and treatment</p>
                                <LandingNavgridImage landingNavgridImage={data.grid4} className={style.NavgridImage}/>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        }}
    />
);
