import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './LandingBanner.module.css';

class LandingBanner extends React.PureComponent {
    render(){
        return (
            <Img
                fluid={this.props.landingBannerImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                landingBanner: file(relativePath: { eq: "landing/landing-banner.png" }) {
                    childImageSharp {
                        fluid(maxWidth:2000){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            // let isIE = /*@cc_on!@*/false || !!document.documentMode;
            let isIE = false;
            
            return (
            <div className={style.LandingBanner}>
                <div className={(isIE ? style.BannerTextShellIE : style.BannerTextShell )}>
                    <div className={(isIE ? style.BannerTextContentIE : style.BannerTextContent)}>
                        <p className={style.BannerText}>Understanding your dog's heart can make a difference</p>
                    </div>
                </div>
                <div className={style.BannerImageShell}>
                    <div className={style.BannerImageContent}>
                        <LandingBanner
                        className={style.BannerImage}
                        landingBannerImage={data.landingBanner}
                        alt="Dog and Owner"
                        />
                    </div>
                </div>
            </div>
        )}}
    />
);
