import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './LandingApp.module.css';
import AppleBadge from '../../images/common/badge-apple.svg';
import GoogleBadge from '../../images/common/badge-google.svg';

class LandingAppImage extends React.PureComponent {
    render(){
        return (
            <Img
                fluid={this.props.appImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                appImage: file(relativePath: { eq: "landing/app-image.png" }) {
                    childImageSharp {
                        fluid(maxWidth:755){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <div className={style.LandingApp}>
                <div className={style.AppImageContainer}>
                    <LandingAppImage appImage={data.appImage} className={style.AppImage}/>
                </div>
                <Link to="/measure-health" className={style.AppContainerLink}>
                    <div className={style.AppTextContainer}>
                        <div className={style.AppTextContent}>
                            <p className={style.AppText1}>Measure your dog's heart health</p>
                            <p className={style.AppText2}>with the Heart2Heart Canine Respiratory App</p>
                            <div className={style.BadgeContainer}>
                                <img src={AppleBadge} className={style.AppLinkImage} alt="App Store Badge" />
                                <img src={GoogleBadge} className={style.AppLinkImage} alt="Google Store Badge" />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )}
    />
);
